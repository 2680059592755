export const SIGN_IN = 'sign_in';
export const SET_USER = 'set_user';
export const VERIFY_TOKEN = 'verify_token';
export const SIGN_OUT = 'sign_out';
export const BREAK_START = 'break_start';
export const BREAK_END = 'break_end';
export const GET_BREAKS = 'get_breaks';
export const SET_BREAKS = 'set_breaks';
export const SET_EMP_BREAKS = 'set_emp_breaks';
export const GET_EMP = 'get_emp';
export const SET_MY_BREAKS = 'set_my_breaks';
export const GET_TIMESHEET = 'get_timesheet';
export const EDIT_LEAVE = 'edit_leave';
export const USERS_ONLINE = 'users_online';
export const PRIVATE_MESSAGE = 'private_message';
export const SELF_USER = 'self_user';
export const EDIT_PRODUCT = 'edit_product';
export const DELETE_PRODUCT = 'delete_product';
