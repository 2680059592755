import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { Redirect } from 'react-router-dom';
import { setUser, signInn, signOut, checkExpiry } from '../../actions/user_actions';
import { config } from '../../siteDetails';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export default function withAuth(ComponentToBeRendered) {
  class Authenticate extends Component {
    state = {
      isAuth: false,
    };
    authCheck = () => {
      // console.log('auth check in hoc')

      try {
        if (localStorage.authToken) {
          axios({
            method: 'GET',
            url: `${config.serverUrl}login/validToken`,
            headers: {
              'x-auth-token': localStorage.authToken,
            },
          })
            .then((res) => {
              if (res.data.message === 'valid') {

                // console.log('authcheck in hoc')
              } else {
                this.props.signOut();
                localStorage.removeItem('authToken');
              }
            })
            .catch((err) => {
              this.props.signOut();
              localStorage.removeItem('authToken');
            });
        } else {
          this.props.signOut();
        }
      } catch (err) {
        this.props.signOut();
      }
    };
    checkExpiry = () => {

      if (localStorage.authToken) {
        const decoded = jwt_decode(localStorage.authToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          localStorage.removeItem('authToken');
          this.props.history.push('/auth/login');
        } else {
              this.props.setUser(localStorage.authToken);
          // console.log(decoded.user.role, decoded.user.uid, 'token still valid');
        }
      } else {
        console.log('no token found');
        this.props.history.push('/auth/login');
      }
    };

    // componentWillMount() {
    //   console.log(this.props.User.auth.message, 'willmount');
    //   if (this.props.User.auth.message === 'invalid') {
    //     this.props.history.push('/auth/login');
    //   }
    // }
    componentDidMount() {
      this.checkExpiry();
      this.authCheck();
      
    }

    render() {
      // if (this.props.User.auth.message !== 'valid') {
      //   console.log('withAuth redirect');
      //   return <Redirect to='/auth/login' />;
      // } else
      return <ComponentToBeRendered {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      User: state.User,
    };
  };
  const mapDispatchToProps = {
    setUser,
    checkExpiry,
    signOut,
    signInn,
  };

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
